import './websiteOuter.css';
import React, { useEffect } from 'react';
import { Reveal } from "../Reveal.tsx";
import { useNavigate } from 'react-router-dom';

const WebsiteOuter = () => {
    const navigate = useNavigate();

    useEffect (() => {
        const seeProject = document.getElementById("seeProjectWebsite")
  
        const toWebsite = () => {
            setTimeout(() => {
                // navigate('/website');
                window.location.pathname = "/website"
            }, 500);
        }
        seeProject.addEventListener("click", toWebsite)
    }, []);

    return (
        <div>
            <div className="separator">
                    <div className='separatorLine1'></div>
                    <div className='separatorLine2'></div>
                </div>
            <div className='websiteProjectHome'>

                <div className='imageContainer'>
                    <video className='websiteVid' playsInline autoPlay controls={null} muted loop height="250px">
                        <source src="/website_vid.mp4" type="video/mp4" />
                    </video>
                </div>

                <div className="projectTextHome">
                    <Reveal><h1 className="projectTitleHome">Researcher's Website</h1></Reveal>
                    <Reveal><h4 className="projectBriefHome">A website created for a lab.</h4></Reveal>
                    <button className="seeProject" id="seeProjectWebsite">See More</button>
                </div>
            </div>
        </div>
    )
}

export default WebsiteOuter;