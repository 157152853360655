import { useEffect } from 'react';
import './cursor.css';

const Cursor = () => {

    useEffect(() => {

        const cursorDot = document.querySelector(".cursorDot");
        const cursorOutline = document.querySelector(".cursorOutline");

        const moveCursor = (e) => {
            const posX = e.clientX;
            const posY = e.clientY;

            if (cursorDot != null) cursorDot.style.left =  `${posX}px`;
            if (cursorDot != null) cursorDot.style.top = `${posY}px`;

            cursorOutline.animate({
                left: `${posX}px`,
                top: `${posY}px`
            }, {duration: 500, fill: "forwards"});
        };

        window.addEventListener("mousemove", moveCursor);

        const handleMouseEnter = (e) => {
            if (!window.getComputedStyle(e.currentTarget).textDecoration.includes('line-through')) {
                cursorOutline.classList.add("large");
            }
        };

        const handleMouseLeave = () => {
            cursorOutline.classList.remove("large");
        }

        const links = document.querySelectorAll("a")
        links.forEach(link => {
            link.addEventListener("mouseenter", handleMouseEnter)
            link.addEventListener("mouseleave", handleMouseLeave)
        });

        const buttons = document.querySelectorAll("button")
        buttons.forEach(b => {
            b.addEventListener("mouseenter", handleMouseEnter)
            b.addEventListener("mouseleave", handleMouseLeave)
        });

        //cleanup
        return () => {
            window.removeEventListener("mousemove", moveCursor);
        
            links.forEach((link) => {
              link.removeEventListener("mouseenter", () => {
                cursorOutline.classList.add("large");
              });
        
              link.removeEventListener("mouseleave", () => {
                cursorOutline.classList.remove("large");
              });
            });

            buttons.forEach(b => {
                b.removeEventListener("mouseenter", handleMouseEnter)
                b.removeEventListener("mouseleave", handleMouseLeave)
                b.removeEventListener("click", handleMouseLeave)
            })
          };
    }, []);

    return (
        <div className="cursor">
            <div class="cursorDot"></div>
            <div class="cursorOutline"></div>
        </div>
    );
}

export default Cursor;