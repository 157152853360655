import './home.css';
import { useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import { Reveal } from "../Reveal.tsx";

const Home= () => {
  useEffect(() => {
    const homeName = document.querySelector(".homeName")
    const characters = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ1234!&%$^#@"]

    window.onload = function() {
    }
  }, []);
  
  return (
    <section id="home">
      <div className='tmp'><video className="homeBac" playsInline autoPlay controls={null} muted loop>
            <source src="/homeBac.mp4" type="video/mp4" />
      </video></div>
      <div className='homeText'>
        <div className="lines">
            <Reveal><span className="Line1">Hi there, I'm{" "}<span className="nameLine1">Mina!</span></span></Reveal>
            <Reveal color="gray"><span className="Line2">A highschool student</span></Reveal>
            <Reveal><span className="Line3">
              who enjoys{" "}
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString("Coding!").pause(4000).deleteAll()
                    .typeString("Tech!").pause(4000).deleteAll()
                    .typeString("Physics!").pause(4000).deleteAll()
                    .typeString("Math!").pause(4000).deleteAll().start();
                }}
                options={{ loop: true }}
              />
            </span></Reveal>
          </div>
        </div>
    </section>
  );
}

export default Home;