import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

interface Props {
    children: JSX.Element;
    width?: "fit-content" | "100%";
    color?: string;
}

export const Reveal = ({ children, width = "fit-content", color="black"}: Props) => {
    const ref = useRef(null);
    const slideControls = useAnimation();

    useEffect (() => {
        const seeProjects = document.querySelectorAll(".seeProject")
        const returns = document.querySelectorAll(".return")

        seeProjects.forEach((button) => {
            button.addEventListener('click', (event) => {
                slideControls.start("hidden");
            })
        })

        returns.forEach((button) => {
            button.addEventListener('click', (event) => {
                slideControls.start("hidden");
            })
        })
    },[]);

    return (
        <div ref={ref} style={{position: "relative", width, overflow: "hidden" }}>
            <motion.div
                transition={{ duration: 0.5, delay: 0.25 }}
                variants={{
                    visible: {opacity: 1, y: 0}
                }}
                initial="visible"
            >
            {children}</motion.div>
            <motion.div
                transition={{ duration: 0.5, ease: "easeIn" }}
                variants={{
                    hidden: { left: 0 },
                    visible: { left: "100%" },
                }}
                initial="visible"
                animate={slideControls}
                style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: color,
                    zIndex: 20,
                }}
            />
        </div>
    );
};