import './myApp.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import appImg1 from "./myApp Images/myApp1.png";
import appImg2 from "./myApp Images/myApp2.png";
import appImg3 from "./myApp Images/myApp3.png";
import { Reveal } from "../../Reveal.tsx";
import { useNavigate } from 'react-router-dom';

const MyApp = () => {
    const navigate = useNavigate();

    useEffect (() => {
        const returnButton = document.querySelector(".return");
    
        returnButton.addEventListener("click", () => {
            setTimeout(() => {
                // navigate('/');
                window.location.pathname = "/"
            }, 500);
        })
    }, []);

    return (
        <div className='appProject'>
            <div className="projectTextContainer">
                <div className='projectTextSubContainer'>
                    <button className="return">←</button>
                    <Reveal><h1 className="projectTitle">Coptic Dictionary App</h1></Reveal>
                    <Reveal color="gray"><h2 className="projectSkills">#SwiftUI, #UIKit</h2></Reveal>
                    <Reveal><h3 className="projectText">This is a Coptic dictionary app created for those who still use Coptic in the world. It is the liturgical language of the Coptic Orthodox Church. The app includes a keyboard that can be added to the device as well as a variety of alogirthms. This project took about 6 months.</h3></Reveal>
                    <Link className="visit" to="https://apps.apple.com/ca/app/coptic-dictionary/id6474273352" target="_blank">Visit</Link>
                </div>
            </div>
            <div className="content">
                <img src={appImg1} alt="" className="appImage"/>
                <img src={appImg2} alt="" className="appImage"/>
                <img src={appImg3} alt="" className="appImage"/>
            </div>
        </div>
    )
}

export default MyApp;