import React, { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import Nav from "./Components/nav/nav"
import Home from "./Components/home/home";
import Cursor from "./Cursor/cursor";
import About from "./Components/about/about"
import Hangman from "./Components/projects/hangman/hangman";
import MyApp from "./Components/projects/myApp/myApp"
import Website from "./Components/projects/website/website"
import Tic from "./Components/projects/Tic Tac Toe/tic"
import Wordle from "./Components/projects/wordle/wordle"
import AppOuter from "./Components/projects/appOuter";
import TicOuter from "./Components/projects/ticOuter";
import HangmanOuter from "./Components/projects/hangmanOuter";
import WebsiteOuter from "./Components/projects/websiteOuter";
import WordleOuter from "./Components/projects/wordleOuter";

function MainContent() {
  return (
    <div>
      <Nav/>
      <Home></Home>
      <AppOuter></AppOuter>
      <WordleOuter></WordleOuter>
      <TicOuter></TicOuter>
      <HangmanOuter></HangmanOuter>
      <WebsiteOuter></WebsiteOuter>
      <About></About>
    </div>
  );
}

function App() {
  return (
    <div>
      <Cursor></Cursor>
      <Routes>
        <Route exact path="/" element={<MainContent/>}/> 
        <Route exact path="/hangman" element={<Hangman/>}/>
        <Route exact path="/app" element={<MyApp/>}/>
        <Route exact path="/website" element={<Website/>}/>
        <Route exact path="/tictactoe" element={<Tic/>}/>
        <Route exact path="/wordle" element={<Wordle/>}/>
      </Routes>
    </div>
  );
}

export default App;