import './about.css';
import profile from './profile_pic.jpg';

const About = () => {
    return(
        <div className="about">
            <div className='aboutText'>
                <h4 className='helloWorld'>Hello World!</h4>
                <h4 className='aboutMe'>Hello there! My name is Mina - a high school student who loves programming, physics, math, and who also has a wide range of hobbies. Check out my projects and work!</h4>
            </div>
            <div className='aboutImgContainer'>
                <img src={profile} alt="" className="profileImage"/>
            </div>
        </div>
    )
};

export default About;
